<template>
  <div class="youth-inscription-summary-page">
    <page-header
      :title="'Fréquentations ' + youthHomeLabel"
      icon="fas fa-person-circle-question"
      :links="getLinks()"
      print-hour
    ></page-header>
    <seances-list-filter
      single-period
      @changed="onListFilterChanged($event)"
      @loaded="onListFilterLoaded($event)"
      :init-home="initHome"
      :init-type="initType"
      :init-period="initPeriod"
    >
    </seances-list-filter>
    <div class="sub-header2">
      <b-row>
        <b-col>
          <b-row>
            <b-col>
              <b-form-checkbox id="includeAbsences" v-model="includeAbsences" @change="loadSummary()">
                Inclure les absences
              </b-form-checkbox>
            </b-col>
            <b-col>
              <b-form-checkbox id="includeWaiting" v-model="includeWaiting" @change="loadSummary()">
                Inclure les en attente
              </b-form-checkbox>
            </b-col>
            <b-col>
              <b-form-checkbox id="birthdays" v-model="birthdays" @change="loadSummary()">
                Voir les anniversaires
              </b-form-checkbox>
            </b-col>
            <b-col class="text-right">
              <a href @click.prevent="loadSummary()" class="small" v-if="isValid">
                <i class="fa fa-refresh"></i> Rafraîchir
              </a>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <limits-indicator-legend></limits-indicator-legend>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div class="inscriptions-summary" ref="docMe">
      <loading-gif :loading-name="loadingName"></loading-gif>
      <div class="section" v-if="isValid">
        <div class="row-line">
          <b>{{ title }}</b>
          {{ printHourValue }}
        </div>
        <div class="warning-text2">
          Ce tableau ne prend en compte ni les sorties ni les séjours
        </div>
        <table class="table small-table" v-if="!isLoading(loadingName) && isLoaded">
          <tr class="text-center">
            <th
              v-for="cell of makeHeaderLine()"
              :key="cell.name"
              :colspan="cell.colspan"
              class=""
              :class="cell.css"
              :style="cell.style"
            >
              {{ cell.value }}
            </th>
          </tr>
          <tr class="text-center">
            <th
              v-for="cell of makeHeaderLine2()"
              :key="cell.name"
              :class="cell.css"
              class=""
              :style="cell.style"
            >
              {{ cell.value }}
            </th>
          </tr>
          <tr v-for="(elt, index) of periodDays" :key="elt.day" :class="(index % 2) ? 'odd': 'even'">
            <td
              v-for="cell of makeLine(elt)"
              :key="cell.name"
              :class="cell.css"
              :style="getCellStyle(cell, elt, false)"
              v-b-tooltip="getLimitTooltip(cell, elt, false)"
            >
              <span v-if="cell.link">
                <router-link
                  :to="getLinkToInscriptions(cell.link)"
                >
                  <span v-show="cell.value !== 0">
                    {{ cell.value }}
                  </span>
                </router-link>
              </span>
              <span v-else>
                <span v-show="cell.value !== 0">
                  {{ cell.value }}
                </span>
              </span>
            </td>
          </tr>
        </table>

        <div v-if="!isLoading(loadingName) && isLoaded && customDays.length">
          <div class="bold">
            Journées avec des groupes spécifiques
          </div>
          <table class="table small-table" v-for="day of customDays" :key="day.day">
            <tr class="text-center">
              <th
                v-for="cell of makeHeaderLine(day)"
                :key="cell.name"
                :colspan="cell.colspan"
                class=""
                :class="cell.css"
                :style="cell.style"
              >
                {{ cell.value }}
              </th>
            </tr>
            <tr class="text-center">
              <th
                v-for="cell of makeHeaderLine2(day)"
                :key="cell.name"
                :class="cell.css"
                class=""
                :style="cell.style"
              >
                {{ cell.value }}
              </th>
            </tr>
            <tr v-for="(elt, index) of getCustomDays(day)" :key="elt.day" :class="(index % 2) ? 'odd': 'even'">
              <td
                v-for="cell of makeLine(elt)"
                :key="cell.name"
                :class="cell.css"
                :style="getCellStyle(cell, elt, false)"
                v-b-tooltip="getLimitTooltip(cell, elt, false)"
              >
                <span v-if="cell.link">
                  <router-link
                    :to="getLinkToInscriptions(cell.link)"
                  >
                    <span v-show="cell.value !== 0">
                      {{ cell.value }}
                    </span>
                  </router-link>
                </span>
                <span v-else>
                  <span v-show="cell.value !== 0">
                    {{ cell.value }}
                  </span>
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div v-if="seances.length && isValid" class="section">
        <div class="row-line"><b>Séances</b></div>
        <table class="table small-table" v-if="isLoaded && periodSeances.length">
          <tr class="text-center">
            <th
              v-for="cell of makeSeanceHeaderLine(false)"
              :key="cell.name"
              :colspan="cell.colspan"
              class=""
              :class="cell.css"
              :style="cell.style"
            >
              {{ cell.value }}
            </th>
          </tr>
          <tr v-for="(elt, index) of periodSeances" :key="elt.seance.id" :class="(index % 2) ? 'odd': 'even'">
            <td
              v-for="cell of makeSeanceLine(elt, false)"
              :key="cell.name"
              :class="cell.css"
              :style="getCellStyle(cell, elt, true)"
              v-b-tooltip="getLimitTooltip(cell, elt, true)"
            >
              <span v-if="cell.link">
                <router-link
                  :to="getLinkToSeanceInscriptions(cell.link)"
                >
                  <span v-show="cell.value !== 0">
                    {{ cell.value }}
                  </span>
                </router-link>
              </span>
              <span v-else>
                <span v-show="cell.value !== 0">
                  {{ cell.value }}
                </span>
              </span>
            </td>
          </tr>
        </table>
        <table class="table small-table" v-for="elt of customSeances" :key="elt.seance.id">
          <tr class="text-center">
            <th
              v-for="cell of makeSeanceHeaderLine(false, elt)"
              :key="cell.name"
              :colspan="cell.colspan"
              class=""
              :class="cell.css"
              :style="cell.style"
            >
              {{ cell.value }}
            </th>
          </tr>
          <tr>
            <td
              v-for="cell of makeSeanceLine(elt, false)"
              :key="cell.name"
              :class="cell.css"
              :style="getCellStyle(cell, elt, true)"
              v-b-tooltip="getLimitTooltip(cell, elt, true)"
            >
              <span v-if="cell.link">
                <router-link
                  :to="getLinkToSeanceInscriptions(cell.link)"
                >
                  <span v-show="cell.value !== 0">
                    {{ cell.value }}
                  </span>
                </router-link>
              </span>
              <span v-else>
                <span v-show="cell.value !== 0">
                  {{ cell.value }}
                </span>
              </span>
            </td>
          </tr>
        </table>
      </div>
      <div v-if="excursions.length" class="section">
        <div class="row-line"><b>Sorties et séjours</b></div>
        <div v-if="periodExcursions.length">
          <table class="table small-table" v-if="!isLoading(loadingName) && isLoaded">
            <tr class="text-center">
              <th
                v-for="cell of makeSeanceHeaderLine(true)"
                :key="cell.name"
                :colspan="cell.colspan"
                class=""
                :class="cell.css"
                :style="cell.style"
              >
                <span  v-show="cell.value !== 0">
                  {{ cell.value }}
                </span>
              </th>
            </tr>
            <tr v-for="(elt, index) of periodExcursions" :key="elt.seance.id" :class="(index % 2) ? 'odd': 'even'">
              <td
                v-for="cell of makeSeanceLine(elt, true)"
                :key="cell.name"
                :class="cell.css"
                :style="getCellStyle(cell, elt, true)"
                v-b-tooltip="getLimitTooltip(cell, elt, true)"
              >
                <span v-if="cell.link" v-show="cell.value !== 0">
                  <router-link
                    :to="getLinkToSeanceInscriptions(cell.link)"
                  >
                    {{ cell.value }}
                  </router-link>
                </span>
                <span v-else v-show="cell.value !== 0">
                  {{ cell.value }}
                </span>
              </td>
            </tr>
          </table>
        </div>
        <div v-if="customExcursions.length && !isLoading(loadingName) && isLoaded">
          <table
            class="table small-table"
            v-for="elt of customExcursions" :key="elt.seance.id"
          >
            <tr class="text-center">
              <th
                v-for="cell of makeSeanceHeaderLine(true, elt)"
                :key="cell.name"
                :colspan="cell.colspan"
                class=""
                :class="cell.css"
                :style="cell.style"
              >
                <span v-show="cell.value !== 0">
                  {{ cell.value }}
                </span>
              </th>
            </tr>
            <tr class="even">
              <td
                v-for="cell of makeSeanceLine(elt, true)"
                :key="cell.name"
                :class="cell.css"
                :style="getCellStyle(cell, elt, true)"
                v-b-tooltip="getLimitTooltip(cell, elt, true)"
              >
                <span v-if="cell.link" v-show="cell.value !== 0">
                  <router-link
                    :to="getLinkToSeanceInscriptions(cell.link)"
                  >
                    {{ cell.value }}
                  </router-link>
                </span>
                <span v-else v-show="cell.value !== 0">
                  {{ cell.value }}
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import PageHeader from '@/components/Layout/PageHeader'
import SeancesListFilter from '@/components/Seances/Config/SeancesListFilter.vue'
import LimitsIndicatorLegend from '@/components/SeanceLimits/LimitsIndicatorLegend.vue'
import { BackendMixin } from '@/mixins/backend'
import { dateToString } from '@/filters/texts'
import router from '@/router'
import store from '@/store'
import { makeAgeGroup, makeSeanceLimit, LimitType } from '@/types/youth'
import { isNumber } from '@/utils/check'
import { BackendApi, openDocument } from '@/utils/http'
import { slugify } from '@/utils/strings'

export default {
  name: 'YouthHomeInscriptionsSummary',
  components: {
    LimitsIndicatorLegend,
    LoadingGif,
    SeancesListFilter,
    PageHeader,
  },
  mixins: [BackendMixin],
  data() {
    return {
      loadingName: 'YouthHomeInscriptionsSummary',
      youthHomes: [],
      seanceTypes: [],
      periods: [],
      selectedYouthHome: null,
      selectedSeanceType: null,
      selectedPeriod: null,
      days: [],
      ageGroups: [],
      excursionAgeGroups: [],
      isLoaded: false,
      hasPicnic: false,
      hasEvening: false,
      limits: [],
      birthdays: false,
      includeAbsences: false,
      includeWaiting: false,
      initHome: 0,
      initType: 0,
      initPeriod: 0,
      seances: [],
      excursions: [],
      printHourValue: '',
      ageGroupsMap: new Map(),
      excursionAgeGroupsMap: new Map(),
      customAgeGroupsMap: new Map(),
    }
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    isValid() {
      return !!((this.selectedYouthHome && this.selectedSeanceType && this.selectedPeriod))
    },
    title() {
      let title = ''
      if (this.isValid) {
        title = this.selectedYouthHome.name + ' - ' + this.selectedSeanceType.name + ' - ' + this.selectedPeriod.name
        if (this.includeAbsences) {
          title += ' (absences incluses)'
        }
        if (this.includeWaiting) {
          title += ' (en attente inclus)'
        }
        if (this.birthdays) {
          title += ' (anniversaires)'
        }
      }
      return title
    },
    periodDays() {
      return this.days.filter(
        elt => {
          const key = dateToString(elt.day, 'YYYY-MM-DD')
          return !this.customAgeGroupsMap.has(key)
        }
      )
    },
    customDays() {
      return this.days.filter(
        elt => {
          const key = dateToString(elt.day, 'YYYY-MM-DD')
          return this.customAgeGroupsMap.has(key)
        }
      )
    },
    periodExcursions() {
      return this.excursions.filter(
        elt => {
          const key = dateToString(elt.seance.date, 'YYYY-MM-DD') + '###' + elt.seance.name
          return !this.customAgeGroupsMap.has(key)
        }
      )
    },
    customExcursions() {
      return this.excursions.filter(
        elt => {
          const key = dateToString(elt.seance.date, 'YYYY-MM-DD') + '###' + elt.seance.name
          return this.customAgeGroupsMap.has(key)
        }
      )
    },
    periodSeances() {
      return this.seances.filter(
        elt => {
          const key = '' + elt.seance.id
          return !this.customAgeGroupsMap.has(key)
        }
      )
    },
    customSeances() {
      return this.seances.filter(
        elt => {
          const key = '' + elt.seance.id
          return this.customAgeGroupsMap.has(key)
        }
      )
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions(['addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    onListFilterChanged(event) {
      this.selectedYouthHome = event.youthHome
      this.selectedSeanceType = event.seanceType
      this.selectedPeriod = event.period
      this.loadSummary()
    },
    onListFilterLoaded(event) {
      this.youthHomes = event.youthHomes
      this.seanceTypes = event.seanceTypes
      this.periods = event.periods
      this.initFromRoute()
      this.loadSummary()
    },
    getSum(elt) {
      const sumOfGroups = {
        morning: 0,
        lunch: 0,
        picnic: 0,
        afternoon: 0,
        evening: 0,
      }
      const customKey = this.customAgeGroupsMap.has(elt.day) ? elt.day : ''
      const ageGroups = customKey ? this.customAgeGroupsMap.get(customKey) : this.ageGroups
      for (const ageGroup of ageGroups) {
        const group = elt.groups.get(ageGroup.id)
        sumOfGroups.morning += group.morning
        sumOfGroups.lunch += group.lunch
        sumOfGroups.picnic += group.picnic
        sumOfGroups.afternoon += group.afternoon
        sumOfGroups.evening += group.evening
      }
      return sumOfGroups
    },
    getDayLimit(elt) {
      const limits = this.limits.filter(
        itm => {
          return (
            (itm.limitType === LimitType.LIMIT_SEANCE_TYPE_AND_PERIOD) ||
            ((itm.limitType === LimitType.LIMIT_DAY) && itm.day === elt.day)
          )
        }
      )
      let overrideLimits = limits.filter(itm => itm.overrideOthers)
      if (overrideLimits.length > 0) {
        return overrideLimits
      }
      return limits
    },
    getSeanceLimit(elt) {
      const limits = this.limits.filter(
        itm => {
          return (
            (itm.limitType === LimitType.LIMIT_SEANCE) || (itm.limitType === LimitType.LIMIT_EXCURSION)
          ) && (elt.seance.id === itm.seanceId)
        }
      )
      let overrideLimits = limits.filter(itm => itm.overrideOthers)
      if (overrideLimits.length > 0) {
        return overrideLimits
      }
      return limits
    },
    getLimit(cell, elt, seance) {
      let limits = seance ? this.getSeanceLimit(elt) : this.getDayLimit(elt)
      let cellLimit = 0
      for (const limitElt of limits) {
        if (cell.groupId) {
          const groups = limitElt.ageGroups.filter(gr => gr.groupId === cell.groupId)
          if (groups.length) {
            const grLimit = groups[0][cell.field + 'Max'] || 0
            if (grLimit && ((cellLimit === 0) || (grLimit < cellLimit))) {
              cellLimit = grLimit
            }
          }
        } else {
          const totalLimit = limitElt[cell.field + 'Max'] || 0
          if ((cellLimit === 0) || (totalLimit < cellLimit)) {
            cellLimit = totalLimit
          }
        }
      }
      return cellLimit
    },
    isBlocked(cell, elt, seance) {
      let limits = seance ? this.getSeanceLimit(elt) : this.getDayLimit(elt)
      for (const limitElt of limits) {
        if (cell.groupId) {
          const groups = limitElt.ageGroups.filter(gr => gr.groupId === cell.groupId)
          if (groups.length) {
            if (groups[0].isBlocked) {
              return true
            }
          }
        } else {
          if (limitElt.isBlocked) {
            return true
          }
        }
      }
      return false
    },
    getCellStyle(cell, elt, seance) {
      let style = ''
      if (cell.css !== 'blank' && isNumber(cell.value)) {
        style = 'text-align: center; border: solid 1px #888;'
        if (this.isBlocked(cell, elt, seance)) {
          style += 'background: #ccc; color: #888;'
        } else {
          let limitValue = this.getLimit(cell, elt, seance)
          if (limitValue) {
            if (cell.value > limitValue) {
              style += 'background: #e96eff; color: #222;'
            } else if (cell.value === limitValue) {
              style += 'background: #ff6e6e; color: #222;'
            } else if (cell.value >= (limitValue - 5)) {
              style += 'background: #ebe770; color: #222;'
            } else {
              style += 'background: #e1ffdd; color: #222;'
            }
          }
        }
      }
      return style
    },
    getLimitTooltip(cell, elt, seance) {
      let limitValue = this.getLimit(cell, elt, seance)
      if (limitValue) {
        if (cell.value > limitValue) {
          const extra = cell.value - limitValue
          return 'sur-effectif ' + extra + ' place' + (extra > 1 ? 's' : '')
        } else if (cell.value === limitValue) {
          return 'complet'
        } else {
          const remaining = limitValue - cell.value
          return 'reste ' + remaining + ' place' + (remaining > 1 ? 's' : '')
        }
      }
      return ''
    },
    makeLine(elt) {
      const line = [
        {
          name: 'day',
          value: dateToString(elt.day, 'dddd D MMMM'),
          link: elt.day,
          css: 'first',
        }
      ]
      const customKey = this.customAgeGroupsMap.has(elt.day) ? elt.day : ''
      const ageGroups = customKey ? this.customAgeGroupsMap.get(customKey) : this.ageGroups
      if (ageGroups.length > 1) {
        const sumOfGroups = this.getSum(elt)
        line.push(
          { name: 'blankTotal', value: ' ', css: 'blank', }
        )
        line.push(
          {
            name: 'morningTotal',
            value: sumOfGroups.morning,
            css: 'number total',
            groupId: 0,
            field: 'morning',
          }
        )
        line.push(
          {
            name: 'lunchTotal',
            value: sumOfGroups.lunch,
            css: 'number total',
            groupId: 0,
            field: 'lunch',
          }
        )
        if (this.hasPicnic) {
          line.push(
            {
              name: 'picnicTotal',
              value: sumOfGroups.picnic,
              css: 'number total',
              groupId: 0,
              field: 'picnic',
            }
          )
        }
        line.push(
          {
            name: 'afternoonTotal',
            value: sumOfGroups.afternoon,
            css: 'number total',
            groupId: 0,
            field: 'afternoon',
          }
        )
        if (this.hasEvening) {
          line.push(
            {
              name: 'eveningTotal',
              value: sumOfGroups.evening,
              css: 'number total',
              groupId: 0,
              field: 'evening',
            }
          )
        }
      }
      for (const ageGroup of ageGroups) {
        line.push(
          { name: 'blank' + ageGroup.id, value: ' ', css: 'blank', }
        )
        line.push(
          {
            name: 'morning' + ageGroup.id,
            value: elt.groups.has(ageGroup.id) ? elt.groups.get(ageGroup.id).morning : 0,
            css: 'number',
            groupId: ageGroup.id,
            field: 'morning',
          }
        )
        line.push(
          {
            name: 'lunch' + ageGroup.id,
            value: elt.groups.has(ageGroup.id) ? elt.groups.get(ageGroup.id).lunch : 0,
            css: 'number',
            groupId: ageGroup.id,
            field: 'lunch',
          }
        )
        if (this.hasPicnic) {
          line.push(
            {
              name: 'picnic' + ageGroup.id,
              value: elt.groups.has(ageGroup.id) ? elt.groups.get(ageGroup.id).picnic : 0,
              css: 'number',
              groupId: ageGroup.id,
              field: 'picnic',
            }
          )
        }
        line.push(
          {
            name: 'afternoon' + ageGroup.id,
            value: elt.groups.has(ageGroup.id) ? elt.groups.get(ageGroup.id).afternoon : 0,
            css: 'number',
            groupId: ageGroup.id,
            field: 'afternoon',
          }
        )
        if (this.hasEvening) {
          line.push(
            {
              name: 'evening' + ageGroup.id,
              value: elt.groups.has(ageGroup.id) ? elt.groups.get(ageGroup.id).evening : 0,
              css: 'number',
              groupId: ageGroup.id,
              field: 'evening',
            }
          )
        }
      }
      return line
    },
    makeHeaderLine(day = null) {
      const style = 'border: solid 1px #888'
      const line = [
        { name: 'day', value: '', }
      ]
      let colspan = 3
      if (this.hasPicnic) {
        colspan += 1
      }
      if (this.hasEvening) {
        colspan += 1
      }
      const customKey = day ? dateToString(day.day, 'YYYY-MM-DD') : ''
      const ageGroups = customKey ? this.customAgeGroupsMap.get(customKey) : this.ageGroups
      if (ageGroups.length > 1) {
        line.push(
          { name: 'blankTotal', value: ' ', css: 'blank', style: '', }
        )
        line.push(
          { name: 'groupTotal', value: 'Totaux', colspan: colspan, css: 'total', style, }
        )
      }
      for (const ageGroup of ageGroups) {
        line.push(
          { name: 'blank' + ageGroup.id, value: ' ', css: 'blank', style: '', }
        )
        line.push(
          { name: 'group' + ageGroup.id, value: ageGroup.name, colspan: colspan, css: '', style, }
        )
      }
      return line
    },
    makeHeaderLine2(day = null) {
      const line = [
        { name: 'day', value: '', css: 'first', }
      ]
      const style = 'border: solid 1px #888; max-width: 16px'
      const customKey = day ? dateToString(day.day, 'YYYY-MM-DD') : ''
      const ageGroups = customKey ? this.customAgeGroupsMap.get(customKey) : this.ageGroups
      if (ageGroups.length > 1) {
        line.push(
          { name: 'blankTotal', value: ' ', css: 'blank', }
        )
        line.push(
          { name: 'morningTotal', value: 'M', css: 'total', style, }
        )
        line.push(
          { name: 'lunchTotal', value: 'R', css: 'total', style, }
        )
        if (this.hasPicnic) {
          line.push(
            { name: 'picnicTotal', value: 'PN', css: 'total', style, }
          )
        }
        line.push(
          { name: 'afternoonTotal', value: 'AM', css: 'total', style, }
        )
        if (this.hasEvening) {
          line.push(
            { name: 'eveningTotal', value: 'S', css: 'total', style, }
          )
        }
      }
      for (const ageGroup of ageGroups) {
        line.push(
          { name: 'blank' + ageGroup.id, value: ' ', css: 'blank', }
        )
        line.push(
          { name: 'morning' + ageGroup.id, value: 'M', css: '', style, }
        )
        line.push(
          { name: 'lunch' + ageGroup.id, value: 'R', css: '', style, }
        )
        if (this.hasPicnic) {
          line.push(
            { name: 'picnic' + ageGroup.id, value: 'PN', css: '', style, }
          )
        }
        line.push(
          { name: 'afternoon' + ageGroup.id, value: 'AM', css: '', style, }
        )
        if (this.hasEvening) {
          line.push(
            { name: 'evening' + ageGroup.id, value: 'S', css: '', style, }
          )
        }
      }
      return line
    },
    makeDay(elt) {
      const day = elt['seance__date']
      const ageGroup = elt['age_group'] || 0
      const morning = elt['seance__morning']
      const afternoon = elt['seance__afternoon']
      const lunch = elt['seance__lunch']
      const picnic = elt['seance__picnic']
      const evening = elt['seance__evening']
      const inscriptions = elt['inscriptions'] * elt['multiplier']

      let line
      const index = this.days.map(elt => elt.day).indexOf(day)
      if (index < 0) {
        line = {
          day: day,
          groups: new Map(),
        }
        const customKey = this.customAgeGroupsMap.has(day) ? day : ''
        const ageGroups = customKey ? this.customAgeGroupsMap.get(customKey) : this.ageGroups
        for (const ageGroup of ageGroups) {
          line.groups.set(
            ageGroup.id,
            {
              morning: 0,
              lunch: 0,
              afternoon: 0,
              picnic: 0,
              evening: 0,
            }
          )
        }
        this.days.push(line)
      } else {
        line = this.days[index]
      }
      const group = line.groups.get(ageGroup)
      if (group) {
        if (morning) {
          group.morning += inscriptions
        }
        if (lunch) {
          group.lunch += inscriptions
        }
        if (afternoon) {
          group.afternoon += inscriptions
        }
        if (evening) {
          group.evening += inscriptions
        }
        if (picnic) {
          group.picnic += inscriptions
        }
        line.groups.set(ageGroup, group)
      }
    },
    makeBaseDay(elt) {
      const day = elt['date']
      if (!elt['excursion']) {
        let line
        const index = this.days.map(elt => elt.day).indexOf(day)
        if (index < 0) {
          line = {
            day: day,
            groups: new Map(),
          }
          const customKey = this.customAgeGroupsMap.has(day) ? day : ''
          const ageGroups = customKey ? this.customAgeGroupsMap.get(customKey) : this.ageGroups
          for (const ageGroup of ageGroups) {
            line.groups.set(
              ageGroup.id,
              {
                morning: 0,
                lunch: 0,
                afternoon: 0,
                picnic: 0,
                evening: 0,
              }
            )
          }
          this.days.push(line)
        }
      }
    },
    makeBaseSeance(elt) {
      const seance = elt['id']
      const seanceName = elt['name']
      const seanceDate = elt['date']
      const excursion = elt['excursion']
      if (excursion) {
        const sep = '###'
        const key = seanceDate + sep + seanceName
        let index = this.excursions.map(
          elt => elt.seance.date + sep + elt.seance.name
        ).indexOf(key)
        if (index < 0) {
          let line = {
            seance: { id: seance, name: seanceName, date: seanceDate, excursion: excursion, },
            groups: new Map(),
          }
          const customKey = this.customAgeGroupsMap.has(key) ? key : ''
          const ageGroups = customKey ? this.customAgeGroupsMap.get(customKey) : this.excursionAgeGroups
          for (const ageGroup of ageGroups) {
            line.groups.set(
              ageGroup.id,
              {
                value: 0,
              }
            )
          }
          this.excursions.push(line)
        }
      } else {
        let index = this.seances.map(elt => elt.seance).indexOf(seance)
        if (index < 0) {
          let line = {
            seance: { id: seance, name: seanceName, date: seanceDate, excursion: excursion, },
            groups: new Map(),
          }
          const key = '' + seance
          const customKey = this.customAgeGroupsMap.has(key) ? key : ''
          const ageGroups = customKey ? this.customAgeGroupsMap.get(customKey) : this.ageGroups
          for (const ageGroup of ageGroups) {
            line.groups.set(
              ageGroup.id,
              {
                value: 0,
              }
            )
          }
          if (customKey) {
            this.seances.push(line)
          }
        }
      }
    },
    makeSeance(elt, excursion) {
      const seance = elt['seance']
      const seanceName = elt['seance__name']
      const seanceDate = elt['seance__date']
      const seanceExcursion = elt['seance__excursion']
      const ageGroup = elt['age_group'] || 0
      const inscriptions = elt['inscriptions'] * elt['multiplier']
      const items = excursion ? this.excursions : this.seances
      let line
      let index
      let key = ''
      if (excursion) {
        const sep = '###'
        key = seanceDate + sep + seanceName
        index = items.map(
          elt => elt.seance.date + sep + elt.seance.name
        ).indexOf(key)
      } else {
        index = items.map(elt => elt.seance.id).indexOf(seance)
        key = '' + seance
      }
      if (index < 0) {
        line = {
          seance: { id: seance, name: seanceName, date: seanceDate, excursion: seanceExcursion, },
          groups: new Map(),
        }
        const ageGroups = excursion ? this.excursionAgeGroups : this.ageGroups
        for (const ageGroup of ageGroups) {
          line.groups.set(
            ageGroup.id,
            {
              value: 0,
            }
          )
        }
        items.push(line)
      } else {
        line = items[index]
      }
      let group = line.groups.get(ageGroup)
      if (group) {
        group.value += inscriptions
        line.groups.set(ageGroup, group)
      } else {
        // Si le groupe n'est pas trouvé, c'est peut-être une sortie/séjour d'un autre group
        const customKey = this.customAgeGroupsMap.has(key) ? key : ''
        let ageGroups = []
        if (customKey) {
          ageGroups = this.customAgeGroupsMap.get(customKey)
          line.groups.set(ageGroup, group)
        } else {
          ageGroups = customKey ? this.customAgeGroupsMap.get(customKey) : this.excursionAgeGroupsMap
          const excursionGroup = this.excursionAgeGroupsMap.get(ageGroup)
          if (excursionGroup) {
            // le group est trouvé, cherche le groupe d'âge correspondant pour cet accueil de loisirs
            let ageGroup2 = this.ageGroupsMap.get(excursionGroup.startAge)
            if (ageGroup2) {
              group = line.groups.get(ageGroup2)
              group.value += inscriptions
              line.groups.set(ageGroup, group)
            }
          }
        }
      }
    },
    makeSeanceHeaderLine(excursion, elt) {
      let ageGroups = []
      let key = ''
      if (elt) {
        if (excursion) {
          key = '' + elt.seance.date + '###' + elt.seance.name
        } else {
          key = '' + elt.seance.id
        }
      }
      if (key && this.customAgeGroupsMap.has(key)) {
        ageGroups = this.customAgeGroupsMap.get(key)
      } else {
        ageGroups = excursion ? this.excursionAgeGroups : this.ageGroups
      }
      const style = 'border: solid 1px #888'
      const line = [
        { name: 'seance', value: '', css: 'first', }
      ]
      if (ageGroups.length > 1) {
        line.push(
          { name: 'blankTotal', value: ' ', css: 'blank', style: '', }
        )
        line.push(
          { name: 'groupTotal', value: 'Totaux', css: 'total', style, }
        )
      }
      for (const ageGroup of ageGroups) {
        line.push(
          { name: 'blank' + ageGroup.id, value: ' ', css: 'blank', style: '', }
        )
        line.push(
          { name: 'group' + ageGroup.id, value: ageGroup.name, css: '', style, }
        )
      }
      return line
    },
    getSeanceSum(ageGroups, elt) {
      const sumOfGroups = {
        value: 0,
      }
      for (const ageGroup of ageGroups) {
        const group = elt.groups.get(ageGroup.id)
        if (group) {
          sumOfGroups.value += group.value
        }
      }
      return sumOfGroups
    },
    makeSeanceLine(elt, excursion) {
      const line = [
        { name: 'seance', value: elt.seance.name, link: elt.seance, }
      ]
      let ageGroups = []
      let key = ''
      if (elt) {
        if (excursion) {
          key = '' + elt.seance.date + '###' + elt.seance.name
        } else {
          key = '' + elt.seance.id
        }
      }
      if (key && this.customAgeGroupsMap.has(key)) {
        ageGroups = this.customAgeGroupsMap.get(key)
      } else {
        ageGroups = excursion ? this.excursionAgeGroups : this.ageGroups
      }
      if (ageGroups.length > 1) {
        const sumOfGroups = this.getSeanceSum(ageGroups, elt)
        line.push(
          { name: 'blankTotal', value: ' ', css: 'blank', }
        )
        line.push(
          {
            name: 'seanceTotal',
            value: sumOfGroups.value,
            css: 'number total',
            groupId: 0,
            field: 'morning',
          }
        )
      }
      for (const ageGroup of ageGroups) {
        line.push(
          { name: 'blank' + ageGroup.id, value: ' ', css: 'blank', }
        )
        line.push(
          {
            name: 'seance' + ageGroup.id,
            value: elt.groups.get(ageGroup.id).value,
            css: 'number',
            groupId: ageGroup.id,
            field: 'morning',
          }
        )
      }
      return line
    },
    async loadSummary() {
      this.isLoaded = false
      if (this.isValid) {
        this.updateRoute()
        let url = '/api/youth/period-inscriptions-summary/' + this.selectedYouthHome.id + '/' +
          this.selectedSeanceType.id + '/' + this.selectedPeriod.id + '/?'
        if (this.includeAbsences) {
          url += '&absence=1'
        }
        if (this.includeWaiting) {
          url += '&waiting=1'
        }
        if (this.birthdays) {
          url += '&birthdays=1'
        }
        this.startLoading(this.loadingName)
        const backendApi = new BackendApi('get', url)
        let counters = []
        let ageGroups = []
        let excursionAgeGroups = []
        let seances = []
        let excursions = []
        let customGroups = {}
        let allSeances = []
        this.limits = []
        try {
          const resp = await backendApi.callApi()
          counters = resp.data.counters
          ageGroups = resp.data.age_groups
          seances = resp.data.seances
          excursions = resp.data.excursions
          excursionAgeGroups = resp.data['excursion_groups'] || []
          customGroups = resp.data['custom_groups'] || {}
          allSeances = resp.data['all_seances'] || []
          this.limits = resp.data.limits.map(makeSeanceLimit)
          this.isLoaded = true
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.ageGroups = []
        if (counters.filter(elt => !elt['age_group']).length) {
          // Il y a un groupe d'âge vide
          this.ageGroups.push(makeAgeGroup())
        }
        this.hasPicnic = false
        this.hasEvening = false
        for (const elt of counters) {
          const picnic = elt['seance__picnic']
          const evening = elt['seance__evening']
          if (picnic) {
            this.hasPicnic = true
          }
          if (evening) {
            this.hasEvening = true
          }
        }
        const ageGroupsMap = new Map()
        this.ageGroups = this.ageGroups.concat(ageGroups.map(makeAgeGroup))
        for (const ageGroup of this.ageGroups) {
          if (ageGroup.id) {
            const lastAge = Math.min(ageGroup.endAge || 18, 18)
            for (let age = ageGroup.startAge; age <= lastAge; age++) {
              ageGroupsMap.set(age, ageGroup.id)
            }
          }
        }
        const excursionAgeGroupsMap = new Map()
        if (ageGroups.length && (excursionAgeGroups.length === 0)) {
          excursionAgeGroups = ageGroups
        }
        if (excursionAgeGroups.length === 0) {
          excursionAgeGroupsMap.set(0, makeAgeGroup())
        }
        for (const ageGroup of excursionAgeGroups) {
          excursionAgeGroupsMap.set(ageGroup.id, makeAgeGroup(ageGroup))
        }
        this.excursionAgeGroups = [...excursionAgeGroupsMap.values()]
        this.ageGroupsMap = ageGroupsMap
        this.excursionAgeGroupsMap = excursionAgeGroupsMap
        const customGroupsMap = new Map()
        for (const [key, value] of Object.entries(customGroups)) {
          customGroupsMap.set(key, value.map(makeAgeGroup))
        }
        this.customAgeGroupsMap = customGroupsMap
        this.days = []
        this.seances = []
        this.excursions = []
        for (const seance of allSeances) {
          this.makeBaseSeance(seance)
          this.makeBaseDay(seance)
        }
        for (const line of counters) {
          this.makeDay(line)
        }
        for (const line of seances) {
          this.makeSeance(line, false)
        }
        for (const line of excursions) {
          this.makeSeance(line, true)
        }
        this.endLoading(this.loadingName)
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'frequentation-' + slugify(this.title)
      const docContent = this.$refs.docMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async printMe() {
      this.printHourValue = moment().format('DD/MM/YYYY HH:mm')
      const that = this
      this.$nextTick(
        async() => {
          let docUrl = '/documents/standard/<key>/pdf/?landscape=1&colors=1'
          const docSlug = 'frequentation-' + slugify(that.title)
          const docContent = that.$refs.docMe.innerHTML.toString()
          try {
            await openDocument(docUrl, docSlug, docContent)
          } catch (err) {
            await that.addError(that.getErrorText(err))
          }
        }
      )
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    getLinkToInscriptions(day) {
      return {
        name: 'youth-homes-day',
        params: {
          day: day,
          youthHome: '' + this.selectedYouthHome.id,
          seanceType: '' + this.selectedSeanceType.id,
          seancePeriod: '' + this.selectedPeriod.id,
        },
      }
    },
    getLinkToSeanceInscriptions(seance) {
      return {
        name: 'workshop-daily-inscriptions',
        params: {
          day: dateToString(seance.date, 'YYYY-MM-DD'),
          id: seance.id,
          listType: seance.excursion ? 'E' : 'S',
        },
      }
    },
    initFromRoute() {
      this.initHome = this.$route.query.home ? +this.$route.query.home : 0
      this.initType = this.$route.query.type ? +this.$route.query.type : 0
      this.initPeriod = this.$route.query.period ? +this.$route.query.period : 0
      this.includeAbsences = this.$route.query.absence === '1'
      this.includeWaiting = this.$route.query.waiting === '1'
      this.birthdays = this.$route.query.birthdays === '1'
    },
    updateRoute() {
      const query = {}
      if (this.selectedYouthHome) {
        query.home = this.selectedYouthHome.id
      }
      if (this.selectedSeanceType) {
        query.type = this.selectedSeanceType.id
      }
      if (this.selectedPeriod) {
        query.period = this.selectedPeriod.id
      }
      query.absence = this.includeAbsences ? '1' : ''
      query.waiting = this.includeWaiting ? '1' : ''
      query.birthdays = this.birthdays ? '1' : ''
      router.push({ path: this.$route.path, query: query, })
    },
    getCustomDays(day) {
      return this.customDays.filter(elt => elt.day === day.day)
    },
  },
}
</script>
<style scoped lang="less">
.inscriptions-summary {
  margin-top: 15px;
}

.small-table th, .small-table td {
  font-size: 12px;
  padding: 5px 2px;
}

.small-table tr td:first-of-type {
  text-align: right;
}

table.table > tr:first-child td,
table.table > tr:first-child th {
  border-color: transparent;
}

table.table > tr.even td,
table.table > tr.even th {
  background: #e0e0e0;
}

table.table > tr:hover td,
table.table > tr:hover th {
  background: #e4d5b0;
}

.sub-header2 {
  padding: 5px;
  background: #eee;
  color: #222;
}
.warning-text2 {
  color: #888;
  color: #222;
  font-style: italic;
  font-size: 14px;
}
.section {
  border-bottom: solid 1px #eee;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.section table {
  margin-top: 5px;
}
table.table > tr > th.first,
table.table > tr > td.first {
  min-width: 300px;
}
.dark-mode {
  .sub-header2 {
    padding: 5px;
    background: #444;
    color: #fff;
  }
  table.table > tr.even td,
  table.table > tr.even th {
    background: #303030;
  }

  table.table > tr:hover td,
  table.table > tr:hover th {
    background: #464138;
    color: #fff;
  }
}
</style>
